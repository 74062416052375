// import { Row, Col, Button } from "react-bootstrap";
import { Button } from "antd";
import React, { useState, useContext } from "react";

import "antd/dist/antd.css";
import * as FileSaver from "file-saver";

import * as XLSX from "xlsx";
import { message } from "antd"
import Highlighter from "react-highlight-words";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";

import axios from "axios";

import {
    Table,
    Input,
    InputNumber,
    Popconfirm,
    Form,
    Typography,
    Space,
    Spin,
    Tooltip,
    Card,
  } from "antd";
// import { Header } from "antd/lib/layout/layout";
import './table.css'

import { FilterContext } from "../../../context/FilterContext";

// .ant-table-thead > tr >th{
//   color: white;
//   background: #3071b9 !important;
// }
// const tableCSS = styled.div({
  
//   '& thead > tr > th': {
//     backgroundColor: 'darkblue',
//     color: 'white',
//   },
  
// });


export const FxEuroTable = (props) => {

    const { filterState } = useContext(FilterContext);
    let transformedData, data, columns, currency, totalRecords;
    const antIcon = <LoadingOutlined style={{ fontSize: 20, fontWeight: "bold" }} spin />
    
    if (filterState.finalData) {
        data = filterState.finalData;
        columns = filterState.columns;
        totalRecords = filterState.totalRecords;
        currency = filterState.filters;
        // console.log(currency);
        transformedData = data.map((record) => {
            record["key"] = record["index"];
            // console.log("Data :::", record, '----', record["key"])
            return record;
        });
    }
    
    let [searchState, setSearchState] = useState("");
    searchState = {
      searchText: "",
      searchedColumn: "",
    };

    let searchInput;

    let getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchState({
                  searchText: selectedKeys[0],
                  searchedColumn: dataIndex,
                });
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
          : "",
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchState.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchState.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    function handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    }

    function handleReset(clearFilters) {
      clearFilters();
      setSearchState({ searchText: "" });
    }

    //Merge array cells
    // const createNewArr=(data)=>{
    //   // console.log(data)
    //   return data.reduce((result, item) => {
    //   //First, take the name field as a new array result
    //       if (result.indexOf(item.CCY) < 0) {
    //           result.push(item.CCY)
    //       }
    //       return result
    //   }, []).reduce((result, name) => {
    //   //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
    //     const children = data.filter(item => item.CCY === name);
    //     // console.log(children)
    //     // console.log(name)
    //     result = result.concat(
    //       children.map((item, index) => ({
    //         ...item,
    //         rowSpan: index === 0 ? children.length : 0,//Add the first row of data to the rowSpan field
    //       }))
    //     )
    //     return result;
    //   }, [])
    // }

    columns = columns.filter(item => item !== "index" &&item !== "Unnamed: 0")
    let tbColumnsList;
    // let sod = [];
    // console.log("Columns ::", columns);
    const ccy = new Set();

    if (columns && columns.length > 0) {
        tbColumnsList = columns.map((tbcolumn) => {
            if (["CCY"].includes(tbcolumn)) {
              // console.log("CCY")
              return {
                title: tbcolumn,
                dataIndex: tbcolumn,
                align: "center",
                width: 100,
                fixed: 'left',
                filters: currency,
                ellipsis: {
                  showTitle: true,
              
                },
                onFilter: (value, record) =>{return record.CCY.indexOf(value) === 0},
                
                render(value, row, index) {
                  
                  return {
                    props: {
                      style: { background: "#004fa3", color: "white"},
                      // rowSpan: record.rowSpan,
                    },
                    children: <div>{value}</div>
                  };
                }
              };
            }

            if (["Ord Amt"].includes(tbcolumn)) {
              
              return {
                title: tbcolumn,
                dataIndex: tbcolumn,
                align: "center",
                width: 100,
                fixed: 'left',
                ellipsis: {
                  showTitle: true,
              
                },
                ...getColumnSearchProps(tbcolumn),
                render(text, record) {
                  return {
                    props: {
                      style: { background: "#3983ce", color: "white"}
                    },
                    children: <div>{text}</div>
                  };
                }
              };
            }

            if (["Branch"].includes(tbcolumn)) {
              
              return {
                title: tbcolumn,
                dataIndex: tbcolumn,
                align: "center",
                width: 100,
                fixed: 'left',
                sorter: true,
                ellipsis: {
                  showTitle: true,
              
                },
                ...getColumnSearchProps(tbcolumn),
                sorter: (a, b) => a.Branch - b.Branch,
                sortDirections: ["descend", "ascend"],
                render(text, record) {
                  return {
                    props: {
                      style: { background: "#222745", color: "whitesmoke"}
                    },
                    children: <div>{text}</div>
                  };
                }
              };
            }
            if (["C/R"].includes(tbcolumn)) {
              
              return {
                title: tbcolumn,
                dataIndex: tbcolumn,
                align: "center",
                width: 41,
                fixed: 'left',
                
                ellipsis: {
                  showTitle: true,
              
                },
                render(text, record) {
                  return {
                    props: {
                      style: { background: "#3983ce85", color: "white"}
                    },
                    children: <div>{text}</div>
                  };
                }
              };
            }
            if (["CCY"].includes(tbcolumn)) {
                return {
                    title: tbcolumn,
                    dataIndex: tbcolumn,
                    align: "center",
                    width: 100,
                    fixed: 'left',
                    ellipsis: {
                      showTitle: true,
                  
                    },
                    ...getColumnSearchProps(tbcolumn),
                    render(text, record) {
                            return {
                              props: {
                                style: { background: "#222745", color: "whitesmoke"}
                              },
                              children: <div>{text}</div>
                            };
                          }
                    
                  };
            } else {
                return{
                    title: tbcolumn,
                    dataIndex: tbcolumn,
                    width: 70,
                    align: "center",
                    ellipsis: {
                        showTitle: false,
                    },
                    render(text, record) {
                      return {
                        props: {
                          style: { background: "#e9f1f9"}
                        },
                        children: <div>{text}</div>
                      };
                    }
                    
                };
            }
          
            

        });
    }

    return(
        <>
        
            <Table
                bordered= "true"
                dataSource={transformedData}
                columns={tbColumnsList}
                size="small"
                width="auto"
                // scroll={{ x: 3500, y: 500 }} 
                scroll={{ x: "max-content", y: 500 }}
                pagination={{
                  defaultPageSize: 15,
                  showSizeChanger: true,
                  pageSizeOptions: ["15", "30", "50", "100"],
                }}
                                
            />
        </>
    );
};
import React, {useContext} from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from './NavbarElements';
import "./topbar.css";
import { AiOutlineLogout } from "react-icons/ai"
import { LogoutOutlined } from "@ant-design/icons"
// import { Navbar, Nav, NavDropdown} from "react-bootstrap";
// import {NavLink} from "react-router-dom"
import Cookies from "js-cookie";
import { FilterContext } from "../../../context/FilterContext"
import { Row, Col, Button, message, Tooltip } from "antd";
import logo from "./travelex-logo-old.svg";
import middlewareLogo from "./middleware-logo.png";
import {logoutUser} from "../../../api/logout"




class Topbar extends React.Component {

  logout = async (e) => {
    try {
  
        let body = JSON.stringify({
            code: Cookies.get('code')
        })
        // console.log("Push logout :::", this.props);
        // console.log("Logout event :::", e);
        // console.log("Recycling url :::", `${process.env.REACT_APP_RECYCLING_URL}`);
        // console.log("Cookies code ::: ", body);
        const res = await logoutUser(body)
  
        if (res && res.message === "Token invalidated") {
            //clear cookies and push to login
            Cookies.remove('expiresIn');
            Cookies.remove('code');
            Cookies.remove('code_challenge');
            Cookies.remove('code_verifier');
            // console.log("code :::", Cookies.get('code'));
            // console.log("Cookies code After ::: ", body);
            // this.props.history.push("/");
            window.location.href = `${process.env.REACT_APP_RECYCLING_URL}`;
        } else {
            message.error(res.message || 'Error Occured');
        }
    } catch (err) {
        console.log('Error Occured');
    }
  }

  
  
  render() {
    return (
      <>
      <div
        style={{
          width: "20%",
          padding: "10px"
        }}
      >
        <div
          style={{
            display: "block"
          }}
        >
          <img
            alt=""
            src={logo}
            width="190px" 
          />
        </div>
      </div>
      <div
        style={{
          width: "70%",
          padding: "10px"
        }}
      >
          <h4 align= "center" style={{ paddingTop: "10px", fontSize:"24px", paddingRight: "25%", fontFamily: "Lato Light", fontWeight: "600" }}>
          Recycling Foreign Currency
         </h4>
      </div>
      <div
        style={{
          width: "10%",
          padding: "15px"
        }}
      >
          {
            window.location.pathname === "/dashboard" ? <Tooltip placement="top" title={"Logout"}><LogoutOutlined style={{float:"right", paddingTop:"10px", height:"30px", width:"35px", fontSize: '28px'}} onClick={() => this.logout()} alt="Logout" /></Tooltip> : null
          }
      </div>
      </>
      // <Row
      //   style={{
      //     backgroundColor: "rgba(255,255,255,.95)",
      //     borderBottom: "1px solid rgba(28,34,67,.2901960784313726)",
      //     width: "100%",
      //     boxSizing: "border-box",
      //     padding: "10px"
      //   }}
      // >
      //   <Col span={1}>
      //     <img
      //       alt=""
      //       src={logo}
      //       width="196px"
      //       height="57px"
      //       className="d-inline-block align-top"
      //     />
      //   </Col>
      //   <Col span={20}>
      //     <h4 align="center"  style={{ paddingLeft: "50px", paddingTop: "10px", fontSize:"27px" }}>
      //       FX Euro Recycling
      //     </h4>
      //   </Col>
      //   <Col span={20} style={{fontSize: "14px"}}>
      //   </Col>        
      // </Row>
    );
  }
}

export default Topbar;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import Topbar from "./components/Layout/Topbar";
import Footer from "./components/Layout/Footer/Footer";

import { FilterComponent } from "./components/Layout/FilterComponent";
import { FilterProvider } from "./context/FilterContext";

function App() {
  return (
    <div style={{height: '100vh', minHeight : '100vh', backgroundColor: "#F9FBFF"}}>
      <Container fluid>
        <BrowserRouter>
          <FilterProvider>
            
            <Row>
              <Topbar />
            </Row>
            
            <Row>
              <FilterComponent/>
            </Row>
            <Row>
              <Footer />
            </Row>
          </FilterProvider>
        </BrowserRouter>
      </Container>
    </div>
  );
}

export default App;

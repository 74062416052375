import Swal from 'sweetalert2';
import { getRandomString, encryptStringWithSHA256, hashToBase64url } from "../utils/index"
import Cookies from 'js-cookie';
import { sleep } from '../utils/index'
import axios from "axios";
import { message } from "antd";

const API_RETRY_DELAY = 1000;

class APICall {

    async request(URL, requestOptions = {}) {
        // console.log("API CALL Started!!!", URL)
        let code = Cookies.get('code');
        // code = "1234"
        // console.log("API CALL CODE :::", code);

        if (code) {

            try {
                // Setting headers
                let headers = {
                    "code": Cookies.get('code'),
                    'code_verifier': Cookies.get('code_verifier'),
                    'applicationId': process.env.REACT_APP_APPLICATION_ID,
                    'x-origin-refer': process.env.REACT_APP_X_ORIGIN_REFER
                };
                // console.log(`Header ${JSON.stringify(headers)}`)

                if (requestOptions.headers) {
                    requestOptions.headers = { ...requestOptions.headers, ...headers }
                }
                else
                    requestOptions.headers = headers;

                // console.log("API CALL request options :::", requestOptions);
                let retryCountLength = 3;
                // console.log("Calling fetch!!!");
                // console.log("API CALL URL :::", URL);
                let response = await axios.get(URL, {
                    headers: requestOptions.headers
                });
                // console.log("API CALL result ::: ", response);
                return response;
                // for (let retryCount = 0; retryCount <= retryCountLength; retryCount++) {
                //     let response = await fetch(URL, requestOptions);
                //     if (response.status === 200) {
                //         resolve(await response);
                //         break;
                //     }
                //    await sleep(API_RETRY_DELAY);
                // }
                // reject('Retry count exceed');
            }
            catch (e) {
                // console.log("Error API Call :::", e);
                // throw e;
                return e;
            }

        }
        else {
            // this.sweetAlert();
            console.log("SweetALert!!!");
        }
    }

    async postRequest(URL, requestOptions = {}) {
        let code = Cookies.get('code');
        // code = "1234";

        if (code) {
            return new Promise(async (resolve, reject) => {
                try {
                    // Setting headers
                    let headers = {
                        "code": Cookies.get('code'),
                        'code_verifier': Cookies.get('code_verifier'),
                        'applicationId': process.env.REACT_APP_APPLICATION_ID,
                        'x-origin-refer': process.env.REACT_APP_X_ORIGIN_REFER
                    };
                    requestOptions.method = "POST"
                    if (requestOptions.headers) {
                        requestOptions.headers = { ...requestOptions.headers, ...headers }
                    }
                    else
                        requestOptions.headers = headers;

                    // console.log(headers)

                    let response = await fetch(URL, requestOptions).catch(e => reject(e));
                    const tempResult = await response.json();
                    // console.log(response.status);
                    // console.log(tempResult);

                    // console.log(resolve(response.json()));

                    resolve([tempResult, response.status]);
                    // await fetch(URL, requestOptions)
                    //     .then(response => response.json())
                    //     .then((result) => {
                    //         console.log(result);
                    //         // let tempResult = resolve(result);
                    //         //return resolve[tempResult, result.status];
                    //         resolve(result)
                    //     }).catch(e => reject(e));

                    // resolve(response.json());

                    // let retryCountLength = 3;
                    // for (let retryCount = 0; retryCount <= retryCountLength; retryCount++) {
                    //     let response = await fetch(URL, requestOptions);
                    //     if (response.status === 200) {
                    //         resolve(await response.json());
                    //         break;
                    //     }
                    //     if (retryCount == retryCountLength){
                    //         console.log("------------++++++++---------------");
                    //         let error = await response.json();

                    //         console.log(error);
                    //         // console.log(response.Response)
                    //         throw error;
                    //     }
                    // }

                    // await sleep(API_RETRY_DELAY);
                    // console.log("---------------------------");


                }
                catch (e) {
                    // console.log("Error API Call :::", e.message);
                    // throw e;
                    // message.error(e.message);

                    reject(e);
                }
            });
        }
        else {
            this.sweetAlert();
        }
    }

    sweetAlert() {
        Swal.fire({
            title: 'Session has expired',
            text: 'Redirecting to the login page',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = `/`;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // window.location.href = `/login`;
            }
        });
    }

    getLoginURL = async () => {
        const state = getRandomString();
        // console.log(`state value is ${state}`);
        //localStorage.setItem("state", state);
        Cookies.set('state', state)
        // Create PKCE code verifier
        const code_verifier = getRandomString();
        // console.log(`code_verifier value is ${code_verifier}`);
        //localStorage.setItem("code_verifier", code_verifier);
        Cookies.set('code_verifier', code_verifier)
        // Create code challenge
        const arrayHash = await encryptStringWithSHA256(code_verifier);
        const code_challenge = hashToBase64url(arrayHash);
        // console.log(`code_challenge value is ${code_challenge}`);
        //localStorage.setItem("code_challenge", code_challenge)
        Cookies.set('code_challenge', code_challenge)
        // Redirtect user-agent to /authorize endpoint
        const LOGIN_URL = process.env.REACT_APP_DOMAIN_NAME + "/oauth2/authorize?response_type=code&state=" + state + "&client_id=" + process.env.REACT_APP_APP_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_REDIRECT_URI + "&scope=openid&code_challenge_method=S256&code_challenge=" + code_challenge;
        // console.log(`LOGIN_URL value is ${LOGIN_URL}`)

        return LOGIN_URL;
    }
}
export default new APICall();
import React, { useReducer, useContext } from "react";
import moment from "moment";
import axios from 'axios';
import dal from '../dal/api-call';

let limit = 500;
let offset = 0;

const initialState = {
  fromDate: "",
  toDate: "",
  country: "",
  reportType: "",
  currency: "",
  data: "",
  columns:"",
  totalRecords: "",
  filters: "",
  stocksFromTillsFlag: false,
  isFetching: false,
  isDataLoading: false,
  finalData: [],
  isFetchingApiData: false,
  isApiDataFetched: false,
  isError: false,
  isHandleSubmitClicked: false,
  errorMessage: "",
  displayMessage: "",
  
};

let reducer = (state, action) => {
  switch (action.type) {
    case "SET_FROM_DATE":
        return{
            ...state,
            fromDate: action.value ? action.value.format("DD/MM/YYYY") : undefined,
            stocksFromTillsFlag: false,
        };
    case "SET_TO_DATE":
        return{
            ...state,
            toDate: action.value ? action.value.format("DD/MM/YYYY") : undefined,
            stocksFromTillsFlag: false,
        };
    case "SET_COUNTRY":
        return{
            ...state,
            country: action.value,
            stocksFromTillsFlag: false,

        };
    case "SET_REPORT_TYPE":
        return{
            ...state,
            reportType: action.value,

        };
    case "SET_CURRENCY":
        return{
            ...state,
            currency: action.value,
        };
    case "SET_OSR_FLAG":
        // console.log("SET_OSR_FLAG")
        return{
            ...state,
            stocksFromTillsFlag: action.value.target.checked,
        };

    case "SET_DATA_LOADING_FLAG":
        // console.log("SET_DATA_LOADING_FLAG")
        return{
            ...state,
            isDataLoading: action.value,
        };
    case "SET_DATA_FETCH_FLAG":
        // console.log("SET_DATA_FETCH_FLAG")
        return{
            ...state,
            isFetching: action.value,

        };
    case "FETCH_DATA":
        return{
            ...state,
            data: action.data,
            columns: action.columns,
            totalRecords: action.totalRecords,
            filters: action.filters,
        };

    case "HANDLE_SUBMIT":
        offset = 0;
        fetchAPiData(action.filterState, action.dispatch)
        return{
            ...state,
            isFetching: true,
            isError: false,
            isHandleSubmitClicked: true,
        };

    case "FETCH_API_DATA":
      let temp = state.finalData;
      temp.push(...action.payload.data);

      return{
          ...state,
          finalData: [...new Set(temp)],
          columns: action.payload.columns,
          totalRecords: action.payload.totalRecords,
          filters: action.payload.filters,
          isDataLoading: true,
          isFetchingApiData: true,
          displayMessage: action.payload.message,
      };
    
    case "RESET_API_DATA":
      return{
          ...state,
          finalData: [],
          columns:[],
          totalRecords: "",
          filters: [],
          isFetchingApiData: false,
          isApiDataFetched: false,
          stocksFromTillsFlag: false,
          isDataLoading: false,
          isError: false,
          isHandleSubmitClicked: false,
      };
    case "API_DATA_STATUS":
      return{
        ...state,
        isApiDataFetched: action.value,
        isDataLoading: false,
        isFetching: false,
        isError: false,
      }

    case "FETCH_ERROR":
        return{
            ...state,
            finalData: [],
            columns:[],
            totalRecords: "",
            filters: [],
            isError: true,
            errorMessage: action.value,
            isApiDataFetched: false,
            isFetching: false,
            isHandleSubmitClicked: false,
        }

    default:
        return;
  }
};



export const FilterContext = React.createContext(initialState);

export const FilterProvider = (props) => {

  const [filterState, filterDispatch] = useReducer(reducer, initialState);

  return (
    <FilterContext.Provider value={{ filterState, filterDispatch }}>
      {props.children}
    </FilterContext.Provider>
  );
};

const fetchAPiData = async (filterState, filterDispatch) => {
    try {
        let [response, statusCode] = await dal.postRequest(`${process.env.REACT_APP_API_BASE_PATH}stockupdate?date=${filterState.toDate}&country=${filterState.country}&offset=${offset}&limit=${limit}&flag=${filterState.stocksFromTillsFlag}`)
        let result = response;
        // console.log("Response :: ", response)
        // let result = response.then(
        //     data => {
        //         console.log("Response Data :::");
        //         console.log(data);
                
        //     }
        // // )
        if(statusCode === 200){;
            filterDispatch({type: "RESET_API_DATA"})
            filterDispatch({type: "FETCH_API_DATA", payload: result});
        }else{
            filterDispatch({type: "FETCH_ERROR", value: result.message});
        }
        
        let offsetCounter = 0;
        if (offset <= result.totalRecords){

            while (offsetCounter <= result.batchSize) {
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;

                let [response, statusCode] = await dal.postRequest(`${process.env.REACT_APP_API_BASE_PATH}stockupdate?date=${filterState.toDate}&country=${filterState.country}&offset=${offset}&limit=${limit}&flag=${filterState.stocksFromTillsFlag}`)
                let result = response;
                if(statusCode === 200){
                    filterDispatch({type: "FETCH_API_DATA", payload: result});
                }
                else{
                    filterDispatch({type: "FETCH_ERROR", value: result.message});
                }

                // filterDispatch({type: "FETCH_API_DATA", payload: result});
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                if(statusCode === 200){
                    filterDispatch({type: "API_DATA_STATUS", value: true});
                    
                }else{
                    filterDispatch({type: "FETCH_ERROR", value: result.message});
                }
                
            }
        }else {
            if (statusCode == 200){
                filterDispatch({type: "API_DATA_STATUS", value: true}); 
            }else{
                filterDispatch({type: "FETCH_ERROR", value: result.message});
            }
            
        }
        
        // return result;

    }catch(error){
        // console.log(error);
        return error;
        // console.log(error.code + "-----"+ error.message);
    }
}